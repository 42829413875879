.bg {
  background-image: url('../../assets//background.webp');
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
  height: 100vh; /* Full height */
  width: 100vw; /* Full width */
  position: absolute; /* Position absolute for full coverage */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  z-index: -1;
}
